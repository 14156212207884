import React, { useState } from 'react';
import { kycUrl } from '../api';
import QrCodeDisplay from './QrCodeDisplay';

function CreateUrl({ token }) {
  const [originalUrl, setOriginalUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await kycUrl(token, originalUrl);
      setShortUrl(data.short_url);
    } catch (err) {
      setError('Failed to secure URL.');
    }
  };

  return (
    <div className="container">
      <h2>CREATE KYC URL</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={originalUrl}
          placeholder="Enter URL"
          onChange={(e) => setOriginalUrl(e.target.value)}
        />
        <button type="submit">Create</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {shortUrl && (
        <div className="url-container">
          <p>KYC URL: <a className="short-url" href={shortUrl}>{shortUrl}</a></p>
          <QrCodeDisplay shortUrl={shortUrl} />
        </div>
      )}

      {/* Footer Security Information */}
      <div className="security-info">
        <img src="/ssl-logo.png" alt="SSL Logo" />
        <span>All URLs are protected with advanced 256-bit encryption.</span>
      </div>
    </div>
  );
}


export default CreateUrl;
