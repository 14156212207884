import React, { useState } from 'react';
import { login } from '../api';

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await login(username, password);
      onLoginSuccess(data.access_token);
    } catch (err) {
      setError('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="container">
      <h2>Secure Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={username}
          placeholder="Email"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Security branding */}
      <div className="branding">
        <img src="/security-logo.png" alt="Norton Security" />
        <p>256-bit SSL Encryption | Norton Secure</p>
      </div>

      {/* Footer Security Information */}
      <div className="security-info">
        <img src="/ssl-logo.png" alt="SSL Logo" />
        <span>We protect your data with state-of-the-art security protocols.</span>
      </div>
    </div>
  );
}

export default Login;
