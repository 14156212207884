import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';  // Use QRCodeCanvas instead of QRCode

function QrCodeDisplay({ shortUrl }) {
  return (
    <div>
      <h3>QR Code:</h3>
      <QRCodeCanvas value={shortUrl} />  {/* Replace QRCode with QRCodeCanvas */}
    </div>
  );
}

export default QrCodeDisplay;
