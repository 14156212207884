import React, { useState } from 'react';
import Login from './components/Login';
import CreateUrl from './components/CreateUrl';

function App() {
  const [token, setToken] = useState('');

  const handleLoginSuccess = (token) => {
    setToken(token);
  };

  return (
    <div className="App">
      {!token ? (
        <Login onLoginSuccess={handleLoginSuccess} />
      ) : (
        <CreateUrl token={token} />
      )}
    </div>
  );
}

export default App;
