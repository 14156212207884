import axios from 'axios';

const API_BASE_URL = 'https://kyc-api.propfusion.io';

export const login = async (username, password) => {
  const data = new URLSearchParams();
  data.append('grant_type', '');
  data.append('username', username);
  data.append('password', password);
  data.append('scope', '');
  data.append('client_id', '');
  data.append('client_secret', '');

  const response = await axios.post(`${API_BASE_URL}/api/login`, data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  return response.data;
};

export const kycUrl = async (token, originalUrl) => {
  const response = await axios.post(
    `${API_BASE_URL}/url/kyc`,
    { original_url: originalUrl },
    {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};
